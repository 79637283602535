import { PageId, WidgetsId } from '../../constants';
import { BasePageMigrator } from './base-page-migrator';
import {
  getBookingsDefId,
  getPageData,
  getTPAMainSectionPageRef,
  getTPAMainSectionStructure,
  updatePageData,
} from '../editor-sdk-actions';
import { MigrationHandler } from '../../migration/migration-handler';
import {
  getSchedulerStyles,
  setBookingsListStyles,
} from '../list-migration/schedule-styles-migration';
import { addBookCheckoutPage } from '../pages-actions';
import { addBookingsPagesAsPanel } from '../pages-panel-actions';

export class ServiceListMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance) {
    super(editorSDK, appToken, instance);
  }

  public async shouldMigrate(): Promise<boolean> {
    const allSitePages = await this.getAllSitePages();
    return !allSitePages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_LIST,
    );
  }

  public async execute() {
    const bookingsDefId = await getBookingsDefId(this.editorSDK);
    const colorsMap = await this.editorSDK.document.theme.colors.getAll();
    const migratory = new MigrationHandler(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    const schedulerSectionStructure = await getTPAMainSectionStructure(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    if (!schedulerSectionStructure) {
      return Promise.resolve();
    }
    const schedulerSectionRef = await getTPAMainSectionPageRef(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    const schedulerData = await getPageData(
      this.editorSDK,
      this.appToken,
      schedulerSectionRef,
    );
    const schedulerStyle = await getSchedulerStyles(
      schedulerSectionStructure.style,
      colorsMap,
    );
    console.log('New Properties:');
    console.table(schedulerStyle);
    await migratory.migrateSection(
      schedulerSectionStructure,
      WidgetsId.BOOKINGS_LIST_PAGE,
      PageId.BOOKINGS_LIST,
    );
    try {
      const bookCheckoutPage = await addBookCheckoutPage(
        this.editorSDK,
        bookingsDefId,
        this.appToken,
        schedulerSectionStructure.style,
      );
      const bookCheckoutPageData = await getPageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
      );
      schedulerData.pageBackgrounds.desktop.ref.id =
        bookCheckoutPageData.pageBackgrounds.desktop.ref.id;
      schedulerData.pageBackgrounds.mobile.ref.id =
        bookCheckoutPageData.pageBackgrounds.mobile.ref.id;
      await updatePageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
        {
          pageSecurity: { ...schedulerData.pageSecurity },
          pageBackgrounds: { ...schedulerData.pageBackgrounds },
        },
      );
    } catch {
      console.log('Checkout page already exists, skipping this');
    }
    await addBookingsPagesAsPanel(this.editorSDK, this.appToken);
    const compId = schedulerSectionStructure.id;

    return setBookingsListStyles(
      this.editorSDK,
      this.appToken,
      schedulerStyle,
      compId,
    )
      .then(async () => {
        return Promise.resolve();
      })
      .catch(async (e) => {
        return Promise.reject('Failed when setting styles: ' + e.message);
      });
  }

  protected get widgetId() {
    return '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  }
}
